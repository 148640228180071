import { NetworkInfo } from "./NetworkInfo";

export const Moonbeam: NetworkInfo = {
  icon: "https://bhst.fra1.digitaloceanspaces.com/network-icons/Moonbeam-Favicon-50.png",
  erc20TimeAndMaterialFactoryAddress: "0xf4bCf4d31281DC5Cc822fB7986885fEB66055a0d",
  etherTimeAndMaterialFactoryAddress: "0x63B5c714065eE360F3906Db6A059d56d0C2681D4",
  params: {
    chainId: "0x504",
    chainName: "Moonbeam",
    nativeCurrency: {
      name: "GLMR",
      symbol: "GLMR",
      decimals: 18,
    },
    rpcUrls: ["https://moonbeam.public.blastapi.io/"],
    blockExplorerUrls: ["https://moonbeam.moonscan.io/"],
  },
  tokens: [
    {
      chainId: 1284,
      address: "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73",
      symbol: "USDT",
      name: "TetherUS",
      decimals: 6,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/usdt.png",
    },
    {
      chainId: 1284,
      address: "0x931715FEE2d06333043d11F658C8CE934aC61D0c",
      symbol: "USDС",
      name: "USD Coin (Wormhole)",
      decimals: 6,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/usdc.png",
    },
    {
      chainId: 1284,
      address: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      symbol: "USDС",
      name: "USD Coin (Multichain)",
      decimals: 6,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/usdc.png",
    },
    {
      chainId: 1284,
      address: "0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F",
      symbol: "BUSD",
      name: "Binance-Peg BUSD",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/busd.png",
    },
    {
      chainId: 1284,
      address: "0x3405A1bd46B85c5C029483FbECf2F3E611026e45",
      symbol: "MATIC",
      name: "Matic",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/polygon.png",
    },
    {
      chainId: 1284,
      address: "0x765277EebeCA2e31912C9946eAe1021199B39C61",
      symbol: "DAI",
      name: "Dai Stablecoin",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/dai.png",
    },
    {
      chainId: 1284,
      address: "0x4792C1EcB969B036eb51330c63bD27899A13D84e",
      symbol: "AVAX",
      name: "Avalanche",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/avalanche.png",
    },
    {
      chainId: 1284,
      address: "0x922D641a426DcFFaeF11680e5358F34d97d112E1",
      symbol: "WBTC",
      name: "Wrapped BTC (Multichain)",
      decimals: 8,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/wbtc.png",
    },
    {
      chainId: 1284,
      address: "0xE57eBd2d67B462E9926e04a8e33f01cD0D64346D",
      symbol: "WBTC",
      name: "Wrapped BTC (Wormhole)",
      decimals: 8,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/wbtc.png",
    },
    {
      chainId: 1284,
      address: "0x322E86852e492a7Ee17f28a78c663da38FB33bfb",
      symbol: "FRAX",
      name: "Frax",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/frax.png",
    },
    {
      chainId: 1284,
      address: "0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327",
      symbol: "FTM",
      name: "Fantom",
      decimals: 18,
      logoURI:
        "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/fantom-ftm.png",
    },
    {
      chainId: 1284,
      address: "0xAcc15dC74880C9944775448304B263D191c6077F",
      symbol: "WGLMR",
      name: "Wrapped GLMR",
      decimals: 18,
      logoURI:
        "https://bhst.fra1.digitaloceanspaces.com/network-icons/Moonbeam-Favicon-50.png",
    },
    {
      chainId: 1284,
      address: "0x18D17A9fD652D7d6a59903E23792ab97F832Ed6C",
      symbol: "BOBA",
      name: "BOBA Token",
      decimals: 18,
      logoURI:
        "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/bobanetworkmb_32.png",
    },
    {
      chainId: 1284,
      address: "0xb564A5767A00Ee9075cAC561c427643286F8F4E1",
      symbol: "mbXEN",
      name: "XEN Crypto",
      decimals: 18,
      logoURI:
        "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/xen_crypto.png",
    },
    {
      chainId: 1284,
      address: "0xcd3B51D98478D53F4515A306bE565c6EebeF1D58",
      symbol: "GLINT",
      name: "Beamswap Token",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/beamswap.png",
    },
    {
      chainId: 1284,
      address: "0xab3f0245B83feB11d15AAffeFD7AD465a59817eD",
      symbol: "WETH",
      name: "Wrapped Ether (Wormhole)",
      decimals: 18,
      logoURI: "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/eth.png",
    },
    {
      chainId: 1284,
      address: "0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2",
      symbol: "STELLA",
      name: "StellaSwap",
      decimals: 18,
      logoURI:
        "https://bhst.fra1.cdn.digitaloceanspaces.com/token-icons/stellaswap.png",
    },
  ],
};
